/* ---------------------------------- */
/* BASIC */
/* ---------------------------------- */

html,
body {
  height: 100%;
}

.w-max-500 {
  max-width: 500px;
}

.items-center {
  align-items: center;
}
.z-9999 {
  z-index: 9999;
}

input[type="text"]:focus,
input[type="submit"]:focus,
button:focus {
  outline: none;
}

/* ---------------------------------- */
/* #snla-css */
/* ---------------------------------- */

.ba {
  border-style: solid;
  border-width: 1px;
}
.bt {
  border-top-style: solid;
  border-top-width: 1px;
}
.bb {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.bn {
  border-style: none;
  border-width: 0;
}
.b--white {
  border-color: #fff;
}
.br-100 {
  border-radius: 100%;
}
.bw2 {
  border-width: 0.25rem;
}
.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}
.absolute--fill {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.dn {
  display: none;
}
.db {
  display: block;
}
.dib {
  display: inline-block;
}
.flex {
  display: -ms-flexbox;
  display: flex;
}
.flex-auto {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0; /* 1 */
  min-height: 0; /* 1 */
}
.flex-column {
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fw5 {
  font-weight: 500;
}
.h-100 {
  height: 100%;
}
.list {
  list-style-type: none;
}
.w-100 {
  width: 100%;
}
.static {
  position: static;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.white {
  color: #fff;
}
.bg-black-50 {
  background-color: rgba(0, 0, 0, 0.5);
}
.bg-light-gray {
  background-color: #eee;
}
.bg-white {
  background-color: #fff;
}
.bg-transparent {
  background-color: transparent;
}
.pa0 {
  padding: 0;
}
.pa2 {
  padding: 0.5rem;
}
.pa3 {
  padding: 1rem;
}
.pb0 {
  padding-bottom: 0;
}
.pb3 {
  padding-bottom: 1rem;
}
.pb5 {
  padding-bottom: 4rem;
}
.pb6 {
  padding-bottom: 8rem;
}
.pt0 {
  padding-top: 0;
}
.pt3 {
  padding-top: 1rem;
}
.ph2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.ph3 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.ph4 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.ma0 {
  margin: 0;
}
.ma2 {
  margin: 0.5rem;
}
.ma3 {
  margin: 1rem;
}
.ml1 {
  margin-left: 0.25rem;
}
.ml3 {
  margin-left: 1rem;
}
.mr3 {
  margin-right: 1rem;
}
.mb0 {
  margin-bottom: 0;
}
.mb2 {
  margin-bottom: 0.5rem;
}
.mb3 {
  margin-bottom: 1rem;
}
.mb4 {
  margin-bottom: 2rem;
}
.mt2 {
  margin-top: 0.5rem;
}
.mt4 {
  margin-top: 2rem;
}
.nl2 {
  margin-left: -0.5rem;
}
.nr2 {
  margin-right: -0.5rem;
}
.underline {
  text-decoration: underline;
}
.no-underline {
  text-decoration: none;
}
.tl {
  text-align: left;
}
.tc {
  text-align: center;
}
.ttu {
  text-transform: uppercase;
}
.f1 {
  font-size: 3rem;
}
.f3 {
  font-size: 1.5rem;
}
.f4 {
  font-size: 1.25rem;
}
.f5 {
  font-size: 1rem;
}
.f6 {
  font-size: 0.875rem;
}
.center {
  margin-right: auto;
  margin-left: auto;
}
.z-999 {
  z-index: 999;
}
body,
ul,
li,
p,
h1,
h2,
h3,
h4,
dl,
dt,
dd,
fieldset {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}
button,
input,
optgroup,
select,
textarea {
  font-family: roboto, arial, Helvetica, Sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
img {
  max-width: 100%;
  border-style: none;
}
body {
  color: #5a5555;
  font-family: roboto, arial, Helvetica, Sans-serif;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  background-color: #f9f7f5;
}

button,
[type="submit"] {
  cursor: pointer;
}
.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;
  width: 100%;
  height: 46px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 1rem;
  background-color: #fff;
  border-radius: 0;
  transition: all 0.2s ease;
  border: 1px solid #b4bdc7;
  font-family: roboto, arial, Helvetica, Sans-serif;
}

dark-grey {
  color: #5a5555;
}
.primary {
  color: #e20221;
}
.b--primary {
  border-color: #e20221;
}
.bg-primary {
  background-color: #e20221;
}
.hover-bg-primary:hover {
  background-color: #cf021e;
}
.hover-bg-primary:active {
  background-color: #c0021c;
}
.hover-b-primary:hover {
  border-color: #cf021e;
}
.hover-b-primary:active {
  border-color: #c0021c;
}
.b-primary {
  border-color: #e20221;
}
.bg--grey {
  background-color: #f9f7f5;
}
.secondary {
  color: #23145f;
}
.b--secondary {
  border-color: #23145f;
}
.bg-secondary {
  background-color: #23145f;
}
.hover-bg-secondary:hover {
  background-color: #1c114e;
}
.hover-bg-secondary:active {
  background-color: #180e42;
}
.hover-white:hover {
  color: #fff;
}
.b--lighter-gray {
  border-color: #ebebeb;
}
b,
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 500;
}
h1,
.h1 {
  font-size: 35px;
  line-height: 1.1;
}
h2,
.h2 {
  font-size: 25px;
  line-height: 1.1;
}
h3,
.h3 {
  font-size: 20px;
  line-height: 1.1;
}
h4,
.h4 {
  font-size: 18px;
  line-height: 1.1;
}
p {
  font-size: 18px;
  line-height: 1.4;
}
.form-button {
  border-width: 3px;
  cursor: pointer;
  max-width: 155px;
  text-transform: uppercase;
}
.form-wide-button {
  min-width: 100px;
  max-width: none;
  width: auto;
}

/* ---------------------------------- */
/* #startside */
/* ---------------------------------- */

#startside {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  height: 100%;
  width: 100%;
}

.startside-popup {
  background: #fff;
  width: 90%;
  box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.2);
  max-width: 650px;
  margin: auto;
}

.startside-img {
  background-repeat: no-repeat;
  background-image: url("../images/vegg-med-nodplakat2.jpg");
  background-position: center;
  background-size: cover;
  width: 50%;
  flex-shrink: 0;
}

/* ---------------------------------- */
/* #search */
/* ---------------------------------- */
#search {
  height: auto;
}
#search-logo {
  height: 32px;
  text-align: left;
}
#search-wrapper-autoInput {
  padding: 0 36px;
  background-image: url("../images/icon-search-navigation.svg");
  background-repeat: no-repeat;
  background-position: left 5px center;
}
#search-wrapper-autoInput.error-no-value {
  border: 2px solid red;
  color: red;
}
.search-wrapper-ulDiv {
  top: 100%; /* Align with search input */
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
}
.search-wrapper-ulDiv li {
  cursor: pointer; /* check button, form-button: hover */
}

#search-form-btn {
  position: absolute;
  bottom: -29px;
  border: 0;
  border-radius: 0; /* For å fikse runde hjørner på iOS */
  width: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}

.arrow-down-white {
  padding-right: 2rem;
  background-image: url("../images/arrow-down-white.svg");
  background-repeat: no-repeat;
  background-size: 1.5rem;
  background-position: right 5px center;
}

.arrow-up-white {
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin: -10px auto 0;
  display: block;
  border: 0;
  padding: 0;
}

.show {
  display: block;
}

/* ---------------------------------- */
/* #map */
/* ---------------------------------- */
#map-wrapper {
  flex: 1 1 auto;
}

#main-geolocate {
  top: 80px;
  left: 10px;
  line-height: 2px; /* usikker hvorfor <a> har noe hvit mellomrom */
  padding: 3px;
  border-radius: 3px;
  cursor: auto;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

#main-geolocate:hover {
  background: #f4f4f4;
}

.map-DmX {
  position: absolute;
  z-index: 9990;
  background: #fff;
  font-size: 1.4rem;
  font-weight: 500;
  bottom: 0px;
  border: 2.5px solid #000;
  border-bottom: none;
  width: 46%;
  left: 27%;
  padding: 10px;
}

#map-formDiv {
  overflow: hidden;
  transition: height 0.3s ease-out; /* auto height tricks in js */
  height: auto;
}

#map-form {
  margin: 0 auto;
}

.input-nyhet {
  margin-right: 8px;
}

/* ---------------------------------- */
/* Responsive */
/* ---------------------------------- */

/* Small phones: from 0 to 480px */
@media only screen and (min-width: 479px) {
  #search {
    flex-direction: column;
    border-style: none;
    border-width: 0;
  }

  .map-DmX {
    padding: 20px;
    width: 50%;
    left: 25%;
  }
}

@media only screen and (max-width: 767px) {
  .startside-img {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  #map-formDiv {
    background-color: #23145f;
  }

  #map-form {
    max-width: 500px;
  }

  .form-dmx {
    display: block;
  }

  #avbryt {
    display: inline-block;
  }

  .arrow-up-white {
    display: none;
  }

  #btn-send {
    min-width: 100px;
    width: auto;
  }

  .startside-title {
    font-size: 35px;
  }
}

@media only screen and (min-width: 1024px) {
  #search {
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* Slik at når auto-suggestion gjør søk lenge, logo fortsett er på top */
  }

  .search-header {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  #search-logo img {
    margin: 0 auto;
  }

  .search-area {
    min-width: 650px;
    padding-top: 1rem;
  }

  #search-form-btn {
    display: inline-block;
    position: static;
    transform: none;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 46px;
    margin-left: 1rem;
  }

  .map-DmX {
    padding: 20px;
    width: 30%;
    left: 35%;
  }
}
